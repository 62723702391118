<template>
  <div>
    <loading-more :fetch-data="fetchData" />
  </div>
</template>

<script>
import LoadingMore from "./components/loading-more";
import { getPatentList, getPatentInfo } from "@/api/company";

const patentColumns = [
  {
    title: "名称",
    dataIndex: "title",
  },
  {
    title: "申请人",
    dataIndex: "assigneeStringList",
  },
  {
    title: "代理机构",
    dataIndex: "agency",
  },
  {
    title: "代理人",
    dataIndex: "agent",
  },
  {
    title: "发明人",
    dataIndex: "inventorStringList",
  },
];

export default {
  name: "patent-manage",
  components: {
    LoadingMore,
  },
  data() {
    return {
      companyId: "",
      patent: {
        columns: patentColumns,
        dataSource: [],
        info: {
          round: "",
          investorName: "",
          moneyNum: "",
          date: "",
          realTime: "",
          patentAdvisor: "",
        },
      },
      ui: {
        loading: false,
        isAddAction: false,
        isEditAction: false,
        showDrawer: false,
        enableAdd: false,
        enableEdit: false,
        enableDelete: false,
      },
    };
  },
  methods: {
    async fetchData(companyId, page) {
      // this.patent.dataSource = await Patent.fetchData(this.companyId);
      return getPatentList({
        companyId,
        page: page,
        pageSize:10
      }).then(async (patentList) => {
        return await getPatentInfo({ companyId }).then(async (patentSum) => {
          return { ...patentList.data, ...{ typeList: patentSum.data } };
        });
      });
    },
  },
};
</script>